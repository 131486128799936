import React from "react";

export default (props) =>
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 32 32"
        fillRule="evenodd"
    >
        <path d="M13.595 28V17.266H10V13h3.595V9.64c0-3.653 2.232-5.64 5.49-5.64 1.56 0 2.9.117 3.29.169v3.815h-2.26c-1.771 0-2.113.844-2.113 2.077V13H22l-.548 4.266h-3.45V28" fill="#76756F"/>
    </svg>


