import React from "react"

import TwitterSVG from '../images/twitter.svg';
import FacebookSVG from '../images/facebook.svg';
import InstagramSVG from '../images/instagram.svg';

const Footer = () => (
    <footer>
        <a href="https://twitter.com/LuxDelux" rel="noopener noreferrer" target="_blank" className="twitter" title="twitter">
            <TwitterSVG/>
        </a>
        <a href="https://www.facebook.com/LuxDelux/" rel="noopener noreferrer" target="_blank" className="facebook" title="facebook">
            <FacebookSVG/>
        </a>
        <a href="https://www.instagram.com/luxdelux/" rel="noopener noreferrer" target="_blank" className="instagram" title="instagram">
            <InstagramSVG/>
        </a>
    </footer>
);

export default Footer
