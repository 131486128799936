import React from "react";

export default (props) =>
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 32 32"
        fillRule="evenodd"
    >
        <path d="M26.523 10.311c.017.233.017.466.017.699 0 7.103-5.407 15.288-15.289 15.288-3.044 0-5.872-.882-8.251-2.412.433.05.848.066 1.298.066 2.512 0 4.824-.848 6.67-2.295a5.383 5.383 0 01-5.024-3.727c.333.05.666.083 1.015.083.483 0 .965-.066 1.414-.183a5.374 5.374 0 01-4.308-5.273v-.067c.715.4 1.547.65 2.428.682a5.37 5.37 0 01-2.395-4.475c0-.998.266-1.913.732-2.711a15.274 15.274 0 0011.08 5.623 6.066 6.066 0 01-.134-1.231 5.37 5.37 0 015.374-5.374 5.36 5.36 0 013.926 1.697 10.58 10.58 0 003.41-1.298 5.359 5.359 0 01-2.362 2.962 10.772 10.772 0 003.094-.832 11.55 11.55 0 01-2.695 2.778z" fill="#76756F"/>
    </svg>