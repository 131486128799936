import React from "react";

export default (props) =>
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 32 32"
        fillRule="evenodd"
    >
        <path d="M16.005 9.867a6.118 6.118 0 00-6.128 6.128 6.118 6.118 0 006.128 6.128 6.118 6.118 0 006.128-6.128 6.118 6.118 0 00-6.128-6.128zm0 10.112a3.991 3.991 0 01-3.984-3.984 3.988 3.988 0 013.984-3.984 3.988 3.988 0 013.984 3.984 3.991 3.991 0 01-3.984 3.984zm7.808-10.363c0 .795-.64 1.43-1.429 1.43a1.43 1.43 0 111.43-1.43zm4.059 1.45c-.09-1.914-.528-3.61-1.93-5.007-1.398-1.398-3.094-1.835-5.009-1.931-1.973-.112-7.888-.112-9.861 0-1.91.09-3.605.528-5.008 1.925-1.403 1.398-1.835 3.094-1.93 5.008-.113 1.974-.113 7.888 0 9.862.09 1.914.527 3.61 1.93 5.008 1.403 1.397 3.093 1.834 5.008 1.93 1.973.112 7.888.112 9.861 0 1.915-.09 3.611-.528 5.008-1.93 1.398-1.398 1.835-3.094 1.931-5.008.112-1.974.112-7.883 0-9.856zm-2.55 11.974a4.033 4.033 0 01-2.271 2.272c-1.574.624-5.307.48-7.046.48-1.738 0-5.477.139-7.045-.48a4.033 4.033 0 01-2.272-2.272c-.624-1.573-.48-5.307-.48-7.045 0-1.739-.139-5.478.48-7.046A4.033 4.033 0 018.96 6.677c1.573-.624 5.307-.48 7.045-.48 1.739 0 5.478-.138 7.046.48a4.033 4.033 0 012.272 2.272c.624 1.574.48 5.307.48 7.046 0 1.738.144 5.477-.48 7.045z" fill="#76756F"/>
    </svg>




