/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"

import Header from "./header"
import Footer from "./footer";
import "./layout.scss"

import LayoutDesktopIndex from '../images/invision/desktop-index.png';
import LayoutTabletIndex from '../images/invision/tablet-index.png';
import LayoutMobileIndex from '../images/invision/mobile-index.png';

import LayoutDesktopServices from '../images/invision/desktop-services.png';
import LayoutTabletServices from '../images/invision/tablet-services.png';
import LayoutMobileServices from '../images/invision/mobile-services.png';

import LayoutDesktopMembership from '../images/invision/desktop-membership.png';
import LayoutTabletMembership from '../images/invision/tablet-membership.png';
import LayoutMobileMembership from '../images/invision/mobile-membership.png';

import LayoutDesktopApply from '../images/invision/desktop-apply.png';
import LayoutTabletApply from '../images/invision/tablet-apply.png';
import LayoutMobileApply from '../images/invision/mobile-apply.png';


const LayoutPreview = () => {

    let path = window.location.pathname.replace(/\//g, '');
    let device = 'desktop';
    let view = null;

    if (window.innerWidth < 1062 + 50) {
        device = 'tablet';
    }

    if (window.innerWidth < 495 + 50) {
        device = 'mobile';
    }

    switch (path) {

        case '': {
            switch (device) {
                case 'mobile': {
                    view = <img src={LayoutMobileIndex} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -94px)',
                        zIndex: 100000,
                        display: 'none',
                        width: '375px',
                        opacity: 0.5
                    }} alt="mobile-index"/>;
                    break;
                }
                case 'tablet': {
                    view = <img src={LayoutTabletIndex} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -69px)',
                        zIndex: 100000,
                        display: 'none',
                        width: '829px',
                        opacity: 0.5
                    }} alt="tablet-index"/>;
                    break;
                }
                default: {
                    view = <img src={LayoutDesktopIndex} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 100000,
                        display: 'none',
                        width: '1685px',
                        opacity: 0.5
                    }} alt="desktop-index"/>;
                }
            }

            break;
        }

        case 'services': {
            switch (device) {
                case 'mobile': {
                    view = <img src={LayoutMobileServices} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -94px)',
                        zIndex: 100000,
                        display: 'none',
                        width: '375px',
                        opacity: 0.5
                    }} alt="mobile-services"/>
                    break;
                }
                case 'tablet': {
                    view = <img src={LayoutTabletServices} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -69px)',
                        zIndex: 100000,
                        display: 'none',
                        width: '829px',
                        opacity: 0.5
                    }} alt="tablet-services"/>
                    break;
                }
                default: {
                    view = <img src={LayoutDesktopServices} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 100000,
                        display: 'none',
                        width: '1436px',
                        opacity: 0.5
                    }} alt="desktop-services" />
                }
            }

            break;
        }

        case 'membership': {
            switch (device) {
                case 'mobile': {
                    view = <img src={LayoutMobileMembership} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -94px)',
                        zIndex: 100000,
                        display: 'none',
                        width: '375px',
                        opacity: 0.5
                    }} alt="mobile-membership"/>
                    break;
                }
                case 'tablet': {
                    view = <img src={LayoutTabletMembership} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -69px)',
                        zIndex: 100000,
                        display: 'none',
                        width: '829px',
                        opacity: 0.5
                    }} alt="tablet-membership"/>
                    break;
                }
                default: {
                    view = <img src={LayoutDesktopMembership} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 100000,
                        display: 'none',
                        width: '1685px',
                        opacity: 0.5
                    }} alt="desktop-membership" />
                }
            }

            break;
        }

        case 'apply': {
            switch (device) {
                case 'mobile': {
                    view = <img src={LayoutMobileApply} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -94px)',
                        zIndex: 100000,
                        display: 'none',
                        width: '375px',
                        opacity: 0.5
                    }} alt="mobile-apply"/>
                    break;
                }
                case 'tablet': {
                    view = <img src={LayoutTabletApply} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, -69px)',
                        zIndex: 100000,
                        display: 'none',
                        width: '829px',
                        opacity: 0.5
                    }} alt="tablet-apply"/>
                    break;
                }

                default: {
                    view = <img src={LayoutDesktopApply} style={{
                        position: 'absolute',
                        top: '0',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 100000,
                        display: 'none',
                        width: '1685px',
                        opacity: 0.5
                    }} alt="desktop-apply"/>
                }
            }

            break;
        }

        default: {

        }
    }

    return (
        <>
            {view}
        </>
    )
};

const Layout = ({children, darkMode, fadeOff}) => {

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
    `);

    let className = "App";
    if(darkMode) {
        className += ' dark';
    }

    return (
        <>
            <div className={className}>
                <Header siteTitle={data.site.siteMetadata.title}/>
                <article>{children}</article>
                {darkMode ? <div className="clear"/> : <Footer/>}
                {process.env.NODE_ENV === 'development' ? <LayoutPreview/> : null}
            </div>
        </>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    darkMode: PropTypes.bool,
};

Layout.defaultProps = {
    darkMode: false
};

export default Layout
